import React, { useEffect, useState } from "react";
import "./PhoneNumberInput.scss";
import { countriesByCountryCode } from "../../data/countries";
import { isSupportedCountry } from "react-phone-number-input/input";
import {
    CountryCallingCode,
    CountryCode,
    getCountryCallingCode,
    isValidPhoneNumber,
} from "libphonenumber-js";

export interface CountryInfo {
    code: string;
    countryName: string;
    callingCode: string;
}

const toCountryInfo = (
    code: CountryCode,
    countryName: string,
    callingCode: CountryCallingCode
): CountryInfo => ({
    code: code as string,
    countryName: countryName,
    callingCode: callingCode as string,
});

const alphabeticallyWithCustomOrdering = (
    a: CountryInfo,
    b: CountryInfo,
    customOrder?: string[]
): number => {
    const alphabeticComparison = a.countryName.localeCompare(b.countryName);
    const priorityOfA = customOrder
        ? Math.max(
              customOrder.length - customOrder.indexOf(a.code),
              alphabeticComparison
          )
        : alphabeticComparison;
    const priorityOfB = customOrder
        ? Math.max(
              customOrder.length - customOrder.indexOf(b.code),
              -alphabeticComparison
          )
        : -alphabeticComparison;
    return priorityOfA - priorityOfB;
};

const allCountries: CountryInfo[] = Object.entries(countriesByCountryCode)
    .filter((codeAndCountryName) =>
        isSupportedCountry(codeAndCountryName[0] as CountryCode)
    )
    .map((codeAndCountryName) => {
        const code = codeAndCountryName[0] as CountryCode;
        return toCountryInfo(
            code,
            codeAndCountryName[1],
            getCountryCallingCode(code)
        );
    });

const phoneInputTranslations = {
    "select-country-code_mouseover-hint": "",
    "input-phone-number_mouseover-hint": "",
    "input-phone-number_placeholder": "",
};
export type PhoneInputTranslations = typeof phoneInputTranslations;

export interface PhoneNumber {
    number: string;
    countryCode: string;
}

interface PhoneNumberInputProps {
    id?: string;
    className?: string;
    customOrder?: string[];
    onChange: (phone: PhoneNumber) => void;
    translate: PhoneInputTranslations;
    defaultCountryCode: string;
    error?: string | null;
}

export const countryCallingCode = (countryCode: string) => {
    return "+" + getCountryCallingCode(countryCode as CountryCode);
};

export const fullPhoneNumber = ({ number, countryCode }: PhoneNumber) =>
    `${countryCallingCode(countryCode)}${number}`;

export const getPhoneNumberErrorKey = (values: PhoneNumber): string | null => {
    if (!values.number) {
        return "phone.error_phone-number-missing";
    } else if (!isValidPhoneNumber(fullPhoneNumber(values))) {
        return "phone.error_phone-number-invalid";
    }
    return null;
};

const PhoneNumberInput = ({
    id,
    className,
    onChange,
    translate,
    customOrder,
    defaultCountryCode,
    error,
}: PhoneNumberInputProps) => {
    const [countries] = useState([...allCountries]);
    useEffect(() => {
        countries.sort((some, other) =>
            alphabeticallyWithCustomOrdering(some, other, customOrder)
        );
    }, [customOrder]);
    const [countryCode, setCountryCode] = useState(
        defaultCountryCode || countries[0].code
    );
    const [number, setNumber] = useState("");
    return (
        <div className={"spond-phone-input"}>
            <div
                id={id}
                className={`input-field ${className || ""} ${
                    error ? "error" : ""
                }`}
            >
                <select
                    id={"countryCode"}
                    data-testid="countryCode"
                    title={translate["select-country-code_mouseover-hint"]}
                    value={countryCode}
                    onChange={(e) => {
                        setCountryCode(e.target.value);
                        onChange({
                            number,
                            countryCode: e.target.value,
                        });
                    }}
                >
                    {countries.map(({ code, callingCode, countryName }, i) => {
                        const isSelected = countryCode === code;

                        const style = isSelected ? { display: "none" } : {};

                        return (
                            <option
                                key={`country-option-${i}`}
                                data-testid={`country-option-${countryName}`}
                                value={code}
                                style={style}
                            >
                                {isSelected
                                    ? `+${callingCode}`
                                    : `${countryName} (+${callingCode})`}
                            </option>
                        );
                    })}
                </select>
                <input
                    id={"phoneNumber"}
                    data-testid="phoneNumber"
                    enterKeyHint="enter"
                    title={translate["input-phone-number_mouseover-hint"]}
                    maxLength={17}
                    type="tel"
                    inputMode="numeric"
                    placeholder={translate["input-phone-number_placeholder"]}
                    onChange={(e) => {
                        setNumber(e.target.value);
                        return onChange({
                            number: e.target.value,
                            countryCode,
                        });
                    }}
                />
            </div>
            {error && (
                <p className={"spi-error"} data-testid={"spi-error"}>
                    {error}
                </p>
            )}
        </div>
    );
};

export default PhoneNumberInput;
