import React from "react";
import "./SimpleCard.scss";

const SimpleCard = ({
    id,
    className,
    icon,
    title,
    text,
}: {
    id?: string;
    className?: string;
    icon: string;
    title: string;
    text: string;
}) => {
    return (
        <div tabIndex={0} id={id} className={`simple-card ${className}`}>
            <img alt={"icon"} className={"simple-card-icon"} src={icon} />
            <h2 className={"simple-card-title"}>{title}</h2>
            <p className={"simple-card-text"}>{text}</p>
        </div>
    );
};

export default SimpleCard;
