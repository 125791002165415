import React from "react";
import { APP_VERSION } from "../../env";
import "./VersionPage.scss";

export const VersionPage = () => {
    return (
        <div id={"version-page"}>
            <div className={"centered"}>
                APP_VERSION:
                <br /> <b>{APP_VERSION}</b>
            </div>
        </div>
    );
};
