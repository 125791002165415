import React from "react";
import GenericErrorPage from "../generic-error-page/GenericErrorPage";
import { useTranslation } from "react-i18next";

const DoesNotExistPage = () => {
    const tErrorPage = useTranslation("common", {
        keyPrefix: "no-such-page",
    }).t;
    return (
        <GenericErrorPage
            title={tErrorPage("subheading_error-title")}
            text={tErrorPage("text_error-description")}
        />
    );
};

export default DoesNotExistPage;
