import React from "react";
import spondLogo from "../../../assets/spond_logo.svg";
import Logo from "../Logo";

const SpondLogo = ({
    id,
    ...props
}: { id?: string } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>) => {
    return <Logo id={id} src={spondLogo} alt={"Spond Logo"} {...props} />;
};

export default SpondLogo;
