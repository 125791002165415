import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { fetchRecruiterInfoByReferralCode } from "../../api/interface";
import { RecruiterInfo } from "../../api/types";
import RecruiterStatsPanelPage from "./stats-page/RecruiterStatsPanelPage";
import RecruiterLogInPage from "./login/RecruiterLogInPage";
import { useCountryCodeFromIpOrFallback } from "../../common/hooks";
import "./stats-page/RecruiterStatsPanelPage.scss";
import GenericErrorPage from "../error/generic-error-page/GenericErrorPage";
import IsNotRecruiterErrorPage from "./is-not-recruiter/IsNotRecruiterErrorPage";
import { useTranslation } from "react-i18next";
import DoesNotExistPage from "../error/does-not-exist/DoesNotExistPage";

enum UserAuthenticationStatus {
    IS_RECRUITER, // show logged in page
    IS_NOT_RECRUITER, // you are not a recruiter page
    IS_NOT_LOGGED_IN, // login page
    FAILED_TO_LOG_IN, // error page an error occured
    NOT_DETERMINED, // empty page
}

function useRecruiterInfoFromBackend() {
    const [loggedIn, setLoggedIn] = useState<UserAuthenticationStatus>(
        UserAuthenticationStatus.NOT_DETERMINED
    );
    const [data, setData] = useState<RecruiterInfo | null>(null);
    const [shouldRetry, setShouldRetry] = useState(0);
    useEffect(() => {
        fetchRecruiterInfoByReferralCode(new AbortController())
            .then((result) => {
                if (result.data) {
                    setLoggedIn(UserAuthenticationStatus.IS_RECRUITER);
                    setData(result.data);
                } else {
                    setLoggedIn(UserAuthenticationStatus.FAILED_TO_LOG_IN);
                }
            })
            .catch((err: AxiosError) => {
                const status = err.response?.status;
                if (status === 401) {
                    setLoggedIn(UserAuthenticationStatus.IS_NOT_LOGGED_IN);
                } else if (status === 403) {
                    setLoggedIn(UserAuthenticationStatus.IS_NOT_RECRUITER);
                } else {
                    setLoggedIn(UserAuthenticationStatus.FAILED_TO_LOG_IN);
                }
            });
    }, [shouldRetry]);
    return {
        loggedInStatus: loggedIn,
        data,
        reload: () => {
            setShouldRetry(shouldRetry + 1);
        },
    };
}

type RecruiterPageSelectorProps = {
    countryCodeTimeoutMs?: number;
};
const RecruiterPageSelector = ({
    countryCodeTimeoutMs,
}: RecruiterPageSelectorProps) => {
    const { t } = useTranslation("recruiter", { keyPrefix: "login-failed" });
    const defaultCountryCode =
        useCountryCodeFromIpOrFallback(countryCodeTimeoutMs);
    const { loggedInStatus, reload, data } = useRecruiterInfoFromBackend();
    if (loggedInStatus === UserAuthenticationStatus.NOT_DETERMINED)
        return <div />;
    else if (loggedInStatus === UserAuthenticationStatus.IS_NOT_LOGGED_IN)
        return defaultCountryCode ? (
            <RecruiterLogInPage
                defaultCountryCode={defaultCountryCode}
                onLogIn={reload}
            />
        ) : (
            <div />
        );
    else if (loggedInStatus === UserAuthenticationStatus.FAILED_TO_LOG_IN)
        return <GenericErrorPage title={t("title")} text={t("explanation")} />;
    else if (loggedInStatus === UserAuthenticationStatus.IS_NOT_RECRUITER)
        return <IsNotRecruiterErrorPage onLogOut={reload} />;
    else if (loggedInStatus === UserAuthenticationStatus.IS_RECRUITER && data)
        return (
            <RecruiterStatsPanelPage recruiterInfo={data!} onLogOut={reload} />
        );
    else return <DoesNotExistPage />;
};

export default RecruiterPageSelector;
