import Card from "../../../../../components/card/Card";
import React from "react";
import Table, { TableRow } from "../../../../../components/table/Table";
import "./ReferralsListCard.scss";
import "../card-styles.scss";

interface ReferralsListCardProps {
    title: string;
    rows: TableRow[];
    text?: string;
}
const ReferralsListCard = ({ rows, title, text }: ReferralsListCardProps) => {
    if (!rows) return null;
    return (
        <Card className={"referrals-list-card rsp-card-styling"} tabIndex={0}>
            <div className={"card-content"}>
                <h2 className={"card-title-variant-1"}>{title}</h2>
                {text && <p>{text}</p>}
            </div>
            <div className={"rsp-card-table"}>
                <Table data={{ rows }} />
            </div>
        </Card>
    );
};

export default ReferralsListCard;
