import React, {
    ForwardedRef,
    KeyboardEventHandler,
    RefObject,
    useEffect,
    useRef,
    useState,
} from "react";

import "./Modal.scss";

export interface ModalProps {
    id?: string;
    className?: string;
    children?: React.ReactNode;
    onMount?: () => void;
    onUnmount?: () => void;
    tabIndex?: number;
}

function useFocusOnInnerDivWhenItBecomesVisible(
    willMount: boolean | null,
    innerRef: RefObject<HTMLDivElement>
) {
    useEffect(() => {
        if (willMount) {
            setTimeout(() => innerRef.current?.focus(), 50);
        }
    }, [willMount, innerRef]);
}

const Modal = React.forwardRef(
    (
        { id, children, className, onMount, onUnmount, tabIndex }: ModalProps,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        const [show, setShow] = useState<boolean | null>(null);
        useEffect(() => {
            if (show) onMount?.();
            else if (show === false) onUnmount?.();
        }, [show]);
        const showClassName = `${show ? " show" : ""}`;
        const innerRef = useRef<HTMLDivElement>(null);
        useFocusOnInnerDivWhenItBecomesVisible(show, innerRef);
        const closeIfEscapeKey: KeyboardEventHandler<HTMLDivElement> = (
            keyDownEvent
        ) => keyDownEvent.key === "Escape" && setShow(false);

        return (
            <div id={id} className={"modal-wrapper"}>
                <div
                    className={"dark-background" + showClassName}
                    ref={ref}
                    onClick={() => setShow(!show)}
                >
                    <div
                        className={
                            "modal" +
                            showClassName +
                            (className ? ` ${className}` : "")
                        }
                        tabIndex={tabIndex ?? 0}
                        ref={innerRef}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={closeIfEscapeKey}
                    >
                        {show ? children : null}
                    </div>
                </div>
            </div>
        );
    }
);

export default Modal;
