import { getI18n } from "react-i18next";
import React, { useState } from "react";
import { fallbackLng, Language } from "../../../internationalization/i18n";
import ButtonGroup from "../../button-group/ButtonGroup";
import { ReactComponent as CheckLg } from "../../../assets/icons/bootstrap/check-lg.svg";
import "./LanguageChanger.scss";
import IconText from "../../icon/IconText";

const LanguageChanger = ({
    onChange,
    flat,
    languages,
}: {
    flat?: boolean;
    onChange: () => void;
    languages: Language[];
}) => {
    const i18n = getI18n();
    const [language, setLanguage] = useState(
        getI18n()?.resolvedLanguage || fallbackLng
    );
    const selectedIndex = languages.findIndex(
        (value) => value.code === language
    );

    return (
        <ButtonGroup
            defaultSelectedIndex={selectedIndex}
            className={`lbm-languages${flat ? "-flat" : ""}`}
            data={languages.map((it, index) => ({
                id: "alt" + index,
                className: "lbm-language",
                index: index,
                children: (
                    <IconText
                        text={it.name}
                        RightIcon={
                            index === selectedIndex ? CheckLg : undefined
                        }
                    />
                ),
            }))}
            onChange={(index) => {
                const lng = languages[index].code;
                i18n.changeLanguage(lng)
                    .then(() => setLanguage(languages[index].code))
                    .then(() => {
                        const searchParams = new URLSearchParams(
                            window.location.search
                        );
                        searchParams.set("lng", lng);
                        const newRelativePathQuery =
                            window.location.pathname +
                            "?" +
                            searchParams.toString();
                        window.history.pushState(
                            null,
                            "",
                            newRelativePathQuery
                        );
                    })
                    .then(() => onChange());
            }}
        />
    );
};

export default LanguageChanger;
