import { MonetaryValue, RecruiterInfo } from "../../../api/types";
import React, { useState } from "react";
import successfulIcon from "../../../assets/icons/referral-successful.svg";
import pendingIcon from "../../../assets/icons/referral-pending.svg";
import failedIcon from "../../../assets/icons/referral-failed.svg";
import StatsPageNavbar from "./navbar/StatsPageNavbar";
import ReferralCodeCard from "./cards/referral-code/ReferralCodeCard";
import ReferralStatusCard, {
    ReferralStatusCardProps,
} from "./cards/referral-status/ReferralStatusCard";
import SuccessfulReferralsCard from "./cards/referral-lists/SucessfulReferralsCard";
import PayoutsListCard from "./cards/referral-lists/PayoutsCard";
import ProfilePictureManagement from "./profile-picture/ProfilePictureManagement";
import { formatCurrency } from "../../../common/utils/utils";
import { getI18n, TFunction, useTranslation } from "react-i18next";

const totalAmountEarned = (bonusCurrency: string, payouts: MonetaryValue[]) =>
    formatCurrency(
        payouts
            .map((it) => it.amount)
            .reduce((sum, current) => sum + current, 0) / 100,
        bonusCurrency,
        getI18n().resolvedLanguage || null
    );

const successfulPendingAndFailedReferralsData = (
    recruiterInfo: RecruiterInfo,
    tStatsPageCards: TFunction
): ReferralStatusCardProps[] => {
    const {
        numberOfSuccessfulReferrals,
        numberOfPendingReferrals,
        numberOfFailedReferrals,
        payouts,
        bonusCurrency,
    } = recruiterInfo.recruiterPrivateInfo;
    return [
        {
            title: tStatsPageCards("cards.rewards.title"),
            icon: successfulIcon,
            bigText: totalAmountEarned(bonusCurrency, payouts),
            content: tStatsPageCards("cards.rewards.content", {
                count: numberOfSuccessfulReferrals,
            }),
        },
        {
            title: tStatsPageCards("cards.pending.title"),
            icon: pendingIcon,
            bigText: tStatsPageCards("cards.pending.subtitle", {
                count: numberOfPendingReferrals,
            }),
            content: tStatsPageCards("cards.pending.content"),
        },
        {
            title: tStatsPageCards("cards.failed.title"),
            icon: failedIcon,
            bigText: tStatsPageCards("cards.failed.subtitle", {
                count: numberOfFailedReferrals,
            }),
            content: tStatsPageCards("cards.failed.content"),
        },
    ];
};

const RecruiterStatsPanelPage = ({
    recruiterInfo,
    onLogOut,
}: {
    recruiterInfo: RecruiterInfo;
    onLogOut: () => void;
}) => {
    const [imageUrl, setImageUrl] = useState<string | null>(
        recruiterInfo.recruiterPublicInfo.optionalData.imageUrl || null
    );
    const { t: tStatsPage } = useTranslation("recruiter", {
        keyPrefix: "stats-page",
    });

    return (
        <div id={"recruiter-stats-panel"}>
            <StatsPageNavbar onLogOut={onLogOut} />
            <main id={"rsp-main"}>
                <ProfilePictureManagement
                    imageUrl={imageUrl}
                    onChange={(newUrl) => setImageUrl(newUrl)}
                />
                <h1 id={"rsp-title"}>
                    {recruiterInfo?.recruiterPublicInfo?.displayName}
                </h1>
                <ReferralCodeCard
                    referralCode={
                        recruiterInfo.recruiterPublicInfo.referralCode
                    }
                />
                {/*<HowReferralWorksCard />*/}
                {successfulPendingAndFailedReferralsData(
                    recruiterInfo,
                    tStatsPage
                ).map((cardProps, i) => (
                    <ReferralStatusCard key={`ref-card-${i}`} {...cardProps} />
                ))}
                <PayoutsListCard recruiterInfo={recruiterInfo} />
                <SuccessfulReferralsCard recruiterInfo={recruiterInfo} />
            </main>
        </div>
    );
};

export default RecruiterStatsPanelPage;
