import SpondLogo from "../../../../components/logo/spond/SpondLogo";
import { ReactComponent as Translate } from "../../../../assets/icons/bootstrap/globe.svg";
import React, { RefObject, useRef, useState } from "react";
import { ReactComponent as BoxArrowRight } from "../../../../assets/icons/bootstrap/box-arrow-right.svg";
import { ReactComponent as SelectCaret } from "../../../../assets/icons/bootstrap/caret-down-fill.svg";
import { postLogOut } from "../../../../api/interface";
import "./StatsPageNavbar.scss";
import IconText from "../../../../components/icon/IconText";
import { ReactComponent as List } from "../../../../assets/icons/bootstrap/list.svg";
import TitledModal from "../../../../components/modal/titled/TitledModal";
import LanguageChanger from "../../../../components/common/language-changer/LanguageChanger";
import { useTranslation } from "react-i18next";
import { languages } from "../../../../internationalization/i18n";

const SettingsMenuModal = ({
    modalRef,
    onLogOut,
}: {
    modalRef: RefObject<HTMLDivElement>;
    onLogOut: () => void;
}) => {
    const { t } = useTranslation("recruiter", {
        keyPrefix: "stats-page.navbar",
    });
    const [showLanguage, setShowLanguage] = useState(false);
    return (
        <TitledModal
            top
            id={"rsp-settings-modal"}
            title={"Settings"}
            ref={modalRef}
        >
            <div id={"rsp-settings"}>
                <button
                    className={"rsp-setting-option"}
                    onClick={() => setShowLanguage(!showLanguage)}
                >
                    <IconText
                        className={"option"}
                        LeftIcon={Translate}
                        RightIcon={SelectCaret}
                        text={t("button_language")}
                    />
                </button>
                {showLanguage && (
                    <div id={"rsp-language"}>
                        <LanguageChanger
                            languages={languages}
                            flat
                            onChange={() =>
                                setTimeout(() => modalRef.current?.click(), 500)
                            }
                        />
                    </div>
                )}
                <button
                    className={"rsp-setting-option"}
                    onClick={() => postLogOut().finally(onLogOut)}
                >
                    <IconText
                        className={"option"}
                        LeftIcon={BoxArrowRight}
                        text={t("button_logout")}
                    />
                </button>
            </div>
        </TitledModal>
    );
};

const StatsPageNavbar = ({ onLogOut }: { onLogOut: () => void }) => {
    const ref: RefObject<HTMLDivElement> = useRef(null);
    const openModal = () => ref.current?.click();
    return (
        <nav id={"rsp-navbar"}>
            <div id={"rsp-navbar-content"}>
                <SpondLogo id={"rsp-nav-left"} tabIndex={0} />
                <button id={"rsp-nav-right"} onClick={openModal}>
                    <List />
                </button>
            </div>
            <SettingsMenuModal onLogOut={onLogOut} modalRef={ref} />
        </nav>
    );
};

export default StatsPageNavbar;
