import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReferralPageSelector from "../pages/referral/ReferralPageSelector";
import DoesNotExistPage from "../pages/error/does-not-exist/DoesNotExistPage";
import RecruiterPageSelector from "../pages/recruiter/RecruiterPageSelector";
import DesktopSuccessPage from "../pages/referral/sign-up/dekstop-success/DesktopSuccessPage";
import { RedirectToStore } from "../pages/referral/sign-up/redirect-to-store/RedirectToStore";
import { VersionPage } from "../pages/version/VersionPage";
import Analytics, { AnalyticsInstance } from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import { GA_TRACKING_ID } from "../env";

const analytics: AnalyticsInstance | null = GA_TRACKING_ID
    ? Analytics({
          app: "referral-frontend",
          plugins: [
              googleAnalytics({
                  measurementIds: [GA_TRACKING_ID],
              }),
          ],
      })
    : null;

export const App = () => {
    analytics?.page();
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/recruiter" element={<RecruiterPageSelector />} />
                <Route path="/version" element={<VersionPage />} />
                <Route
                    path="/:referralCode"
                    element={<ReferralPageSelector />}
                />
                <Route
                    path={"/referral/redirect"}
                    element={<RedirectToStore />}
                />
                <Route
                    path="/referral/success"
                    element={<DesktopSuccessPage timeoutMsMobile={1000} />}
                />
                <Route path="*" element={<DoesNotExistPage />} />
            </Routes>
        </BrowserRouter>
    );
};
