import React, {
    DetailedHTMLProps,
    FunctionComponent,
    HTMLAttributes,
    SVGProps,
} from "react";
import "./IconText.scss";

export type IconTextProps = {
    LeftIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    RightIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    text: React.ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const IconText = ({
    LeftIcon,
    text,
    RightIcon,
    className,
    ...rest
}: IconTextProps) => {
    const cn = `${className || ""} icon-text`;
    return (
        <div className={cn} {...rest}>
            {LeftIcon && <LeftIcon className={"left-icon"} />}
            <div className={"text"}>{text}</div>
            {RightIcon && <RightIcon className={"right-icon"} />}
        </div>
    );
};

export default IconText;
