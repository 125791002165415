import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { iAmAnIframe, linkToStore } from "../../../../common/utils/utils";

export const RedirectToStore = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const linkForPhoneUsers: string | null = linkToStore();
        /*
         * All users, including mobile users, are sent to referral/success
         * Mobile users will automatically open Google Play / App Store (see if statement below).
         * If mobile users for some reason hit the "back" button, they will be taken to the referral/success page.
         * */
        navigate("/referral/success", {
            replace: true,
            state: { loading: !!linkForPhoneUsers },
        });
        if (linkForPhoneUsers && window?.location?.replace) {
            /*
             * Why not just window.location.replace for both cases below?
             * Because that would have Google Play refusing to connect for mobile users who sign up through an iframed version of the referral page.
             *
             * But then why not just use window.open() for both cases?
             * Because then users that follow the QR code at the referral/success page will experience a message that "a pop up window was blacked" when they try to follow the link from the QR code.
             * */
            if (iAmAnIframe()) window.open(linkForPhoneUsers);
            else window.location.replace(linkForPhoneUsers);
        }
    }, []);
    return null;
};
