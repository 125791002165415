import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import appStore from "../../../../assets/store-badges/app-store.png";
import googlePlay from "../../../../assets/store-badges/google-play.png";
import appGallery from "../../../../assets/store-badges/app-gallery.png";
import { isMobile } from "react-device-detect";
import { useStateWhileMounted } from "../../../../common/hooks";
import SpondLogo from "../../../../components/logo/spond/SpondLogo";
import { ReactComponent as PhoneOutlineUpper } from "../../../../assets/success-page/phone-outline-upper.svg";
import { QRCodeSVG } from "qrcode.react";
import "./DesktopSuccessPage.scss";

const storeBadges = [
    {
        name: "app-store",
        icon: appStore,
        link: "https://app.spond.com/homepage_footer?store=app",
    },
    {
        name: "google-play",
        icon: googlePlay,
        link: "https://app.spond.com/homepage_footer?store=play",
    },
    {
        name: "app-gallery",
        icon: appGallery,
        link: "https://appgallery.cloud.huawei.com/ag/n/app/C101944291?channelId=homepage&referrer=homepage_footer&id=6d18303f135d4f53952b5148212563e2&s=5EF76D6A490173A9011C5E128322960139D1685F9FB5C5C5A4E2786DB55BF818&detailType=0&v=",
    },
];
const base = window.location.host;
const qrLink = base + "/referral/redirect";

function useTimeout(ms: number) {
    const [timedOut, setTimedOut] = useStateWhileMounted(false);
    useEffect(() => {
        setTimeout(() => {
            setTimedOut(true);
        }, ms);
    }, []);
    return timedOut;
}

const DesktopSuccessPage = ({
    timeoutMsMobile,
}: {
    timeoutMsMobile: number;
}) => {
    const { t } = useTranslation("referral", {
        keyPrefix: "almost-done",
    });
    const mobileDelayTimeout = useTimeout(timeoutMsMobile);
    if (!mobileDelayTimeout && isMobile) return null;
    return (
        <main id={"desktop-success-page"}>
            <div id={"dsp-center-content"}>
                <div id={"download-app-prompt"}>
                    <div id={"pad"}>
                        <SpondLogo id={"spond-logo"} />
                        <h1 role={"title"} id={"main-prompt"}>
                            {t("title")}
                        </h1>
                        <h3 id={"prompt-details"}>{t("explanation")}</h3>
                    </div>
                    <div id={"store-badges"}>
                        {storeBadges.map((badge, index) => (
                            // TODO Consider adding noreferer
                            <a
                                key={`badge-${index}`}
                                href={badge.link}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <img
                                    className={badge.name}
                                    alt={badge.name}
                                    src={badge.icon}
                                />
                            </a>
                        ))}
                    </div>
                </div>
                {!isMobile && (
                    <div id={"scan-qr-illustration"}>
                        <PhoneOutlineUpper />
                        <div id={"qr-code-container"}>
                            <QRCodeSVG
                                size={170}
                                includeMargin={false}
                                bgColor={"white"}
                                fgColor={"black"}
                                level={"L"}
                                value={qrLink}
                            />
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
};

export default DesktopSuccessPage;
