import React, { LegacyRef } from "react";
import "./Input.scss";

const Input = React.forwardRef(
    (
        {
            ...props
        }: {
            error?: string;
        } & React.InputHTMLAttributes<HTMLInputElement>,
        ref: LegacyRef<HTMLInputElement>
    ) => {
        const { id, type, placeholder, error, ...rest } = props;
        return (
            <div className={"input-wrapper"}>
                <div id={id} className={`input-field ${error ? "error" : ""}`}>
                    <input
                        ref={ref}
                        type={type}
                        placeholder={placeholder}
                        {...rest}
                    />
                </div>
                {error && (
                    <p className={"input-error"} data-testid={"input-error"}>
                        {error}
                    </p>
                )}
            </div>
        );
    }
);

export default Input;
