import attendanceIcon from "../../../assets/icons/icon-attendence.svg";
import communicationIcon from "../../../assets/icons/icon-communication.svg";
import giftIcon from "../../../assets/icons/icon-gift.svg";
import { CountryInfo } from "../../../components/phone-number/PhoneNumberInput";
import { isSupportedCountry } from "react-phone-number-input/input";
import {
    CountryCallingCode,
    CountryCode,
    getCountryCallingCode,
} from "libphonenumber-js";
import { countriesByCountryCode } from "../../../data/countries";

export interface ReasonToUseSpond {
    icon: string;
    title: string;
    text: string;
}

const namespace = "why-use-spond";

export const reasonsToUseSpond: ReasonToUseSpond[] = [
    {
        icon: attendanceIcon,
        title: `${namespace}.reason1.title`,
        text: `${namespace}.reason1.text`,
    },
    {
        icon: communicationIcon,
        title: `${namespace}.reason2.title`,
        text: `${namespace}.reason2.text`,
    },
    {
        icon: giftIcon,
        title: `${namespace}.reason3.title`,
        text: `${namespace}.reason3.text`,
    },
];

const toCountryInfo = (
    code: CountryCode,
    countryName: string,
    callingCode: CountryCallingCode
): CountryInfo => ({
    code: code as string,
    countryName: countryName,
    callingCode: callingCode as string,
});

export const allCountries: CountryInfo[] = Object.entries(
    countriesByCountryCode
)
    .filter((codeAndCountryName) =>
        isSupportedCountry(codeAndCountryName[0] as CountryCode)
    )
    .map((codeAndCountryName) => {
        const code = codeAndCountryName[0] as CountryCode;
        return toCountryInfo(
            code,
            codeAndCountryName[1],
            getCountryCallingCode(code)
        );
    });
