import React from "react";
import "./Logo.scss";

const Logo = ({
    id,
    src,
    alt,
    className,
    ...props
}: { src: string; alt: string } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>) => {
    return (
        <div
            id={id}
            className={"logo" + (className ? ` ${className}` : "")}
            {...props}
        >
            <img src={src} alt={alt} />
        </div>
    );
};

export default Logo;
