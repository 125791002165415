import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { fetchCountryCode } from "../api/interface";
import { isObject } from "./utils/utils";

export function useCountryCodeFromIpOrFallback(
    timeoutMs: number = 2000
): string | null {
    const defaultCountryCode = "NO";
    const [countryCode, setCountryCode] = useState<string | null>(null);
    const [timedOut, setTimedOut] = useState(false);
    useEffect(() => {
        fetchCountryCode()
            .then((result) => {
                const countryCode = result.data;
                if (!timedOut) {
                    setCountryCode(
                        countryCode && !isObject(countryCode)
                            ? (countryCode as string).toUpperCase()
                            : defaultCountryCode
                    );
                }
            })
            .catch((_) => setCountryCode(defaultCountryCode));
        setTimeout(() => {
            setTimedOut(true);
            if (!countryCode) {
                setCountryCode(defaultCountryCode);
            }
        }, timeoutMs);
    }, []);
    return countryCode;
}

/**
 * Does the same as React.useState while blocking calls to setValue after the owning component has unmounted,
 * thus effectively avoiding "must be wrapped in act" errors.
 * @param initialState  same as in React.useState. Initial value of the variable.
 */
export function useStateWhileMounted<S>(
    initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>] {
    const isAborted = useRef(false);
    const [value, setValueInternally] = useState(initialState);
    const setValue = useCallback((val: any) => {
        if (isAborted.current) return;
        setValueInternally(val);
    }, []);
    useEffect(
        () => () => {
            isAborted.current = true;
        },
        []
    );
    return [value, setValue];
}
