import React from "react";
import "./GenericErrorPage.scss";

type GenericErrorPageProps = {
    title?: string;
    text?: string;
    children?: React.ReactNode;
};
const GenericErrorPage = ({ title, text, children }: GenericErrorPageProps) => {
    return (
        <main>
            <div id={"generic-error-page"}>
                <div id={"gep-banner"}>
                    <div id={"gep-constrict"}>
                        {title && <h1 id={"gep-heading"}>{title}</h1>}
                        {text && <small id={"gep-description"}>{text}</small>}
                        {children}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default GenericErrorPage;
