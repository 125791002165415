import React, {
    ChangeEvent,
    RefObject,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    updateRecruiterProfilePicture,
    uploadImage,
} from "../../../../api/interface";
import { None } from "../../../../library/types";
import { ProfilePictureOrTorso } from "../../../../components/profile-picture/ProfilePictureOrTorso";
import Err from "../../../../components/error/Err";
import TitledModal from "../../../../components/modal/titled/TitledModal";
import "./ProfilePictureManagement.scss";
import Button from "../../../../components/button/Button";
import { ReactComponent as TrashFill } from "../../../../assets/icons/bootstrap/trash-fill.svg";
import { ReactComponent as Upload } from "../../../../assets/icons/bootstrap/upload.svg";
import { useTranslation } from "react-i18next";

const FILE_UPLOAD_FAILED_UNEXPECTEDLY_MESSAGE =
    "Image upload failed unexpectedly. Please wait a bit, and try again.";

function getFileSafely(e: ChangeEvent<HTMLInputElement>) {
    const imageFiles = e?.target?.files;
    const imageFilesLength = imageFiles?.length;
    return imageFilesLength && imageFilesLength > 0
        ? imageFiles?.item(0)
        : null;
}

function uploadImageAndSetImageUrl(
    e: ChangeEvent<HTMLInputElement>
): Promise<string> {
    return new Promise((resolve, reject) => {
        try {
            const imageFile = getFileSafely(e);
            if (imageFile) {
                return uploadImage(imageFile!)
                    .then((res) => {
                        const responseUrl: string | None = res.data?.[0]?.url;
                        if (responseUrl)
                            return updateRecruiterProfilePicture(responseUrl)
                                .then(() => resolve(responseUrl))
                                .catch(() => {
                                    reject(
                                        FILE_UPLOAD_FAILED_UNEXPECTEDLY_MESSAGE
                                    );
                                });
                        reject("No URL on response.");
                    })
                    .catch((err) =>
                        reject(
                            err.response?.status === 400
                                ? "Image upload failed because the image file size is too large. Please try again with a smaller file."
                                : FILE_UPLOAD_FAILED_UNEXPECTEDLY_MESSAGE
                        )
                    );
            } else {
                reject("");
            }
        } catch (e) {
            reject(FILE_UPLOAD_FAILED_UNEXPECTEDLY_MESSAGE);
        }
    });
}

const UploadProfileImageModal = ({
    openRef,
    defaultUrl,
    onChange,
}: {
    openRef: RefObject<HTMLDivElement>;
    defaultUrl?: string | null;
    onChange: (url: string | null) => void;
}) => {
    const [profilePictureError, setProfilePictureError] = useState<string>("");
    const [shouldClose, setShouldClose] = useState(false);
    useEffect(() => {
        if (shouldClose) {
            setTimeout(() => {
                setShouldClose(false);
                setProfilePictureError("");
                openRef.current?.click();
            }, 1000);
        }
    }, [shouldClose]);

    const compositeOnChange = (url: string | null) => {
        onChange(url);
        setShouldClose(true);
    };
    const resetModal = () => {
        setShouldClose(false);
        setProfilePictureError("");
    };

    const { t } = useTranslation("recruiter", {
        keyPrefix: "stats-page.profile-picture.modal",
    });
    const fileUploadRef = useRef<HTMLInputElement>(null);
    return (
        <TitledModal
            id={"profile-picture-modal"}
            ref={openRef}
            title={t("title")}
            onUnmount={resetModal}
        >
            {t("explanation")}
            {profilePictureError && <Err message={profilePictureError} />}
            <div id={"ppmo-split"}>
                <ProfilePictureOrTorso
                    id={"ppmo-profile-picture"}
                    imageUrl={defaultUrl}
                    disabled={true}
                />
                <div id={"ppmo-management-buttons"}>
                    <Button
                        id={"ppmo-upload"}
                        onClick={() => fileUploadRef.current?.click()}
                    >
                        <Upload className={"icon"} />
                        {t("button_upload-picture")}
                    </Button>
                    <Button
                        id={"ppmo-remove"}
                        level={"secondary"}
                        onClick={() =>
                            updateRecruiterProfilePicture(null)
                                .then(() => compositeOnChange(null))
                                .catch(() =>
                                    setProfilePictureError(
                                        "Removal failed unexpectedly. Please wait a bit, and try again."
                                    )
                                )
                        }
                    >
                        <TrashFill className={"icon"} />
                        {t("button_remove-picture")}
                    </Button>
                </div>
            </div>
            <input
                data-testid={"profile-image-upload"}
                type={"file"}
                accept={"image/*"}
                style={{ display: "none" }}
                onChange={(e) =>
                    uploadImageAndSetImageUrl(e)
                        .then((responseUrl) => compositeOnChange(responseUrl))
                        .catch((err) => setProfilePictureError(err))
                }
                ref={fileUploadRef}
            />
        </TitledModal>
    );
};

const ProfilePictureManagement = ({
    imageUrl,
    onChange,
}: {
    imageUrl?: string | null;
    onChange: (url: string | null) => void;
}) => {
    const profilePictureModalRef: RefObject<HTMLDivElement> = useRef(null);
    const toggleModal = () => profilePictureModalRef.current?.click();
    // TODO Make test that modal can be opened both with key and click
    return (
        <div id={"profile-picture-management"}>
            <ProfilePictureOrTorso
                tabIndex={0}
                id={"ppm-clickable"}
                onClick={toggleModal}
                showEditIcon
                imageUrl={imageUrl}
            />
            <UploadProfileImageModal
                openRef={profilePictureModalRef}
                defaultUrl={imageUrl}
                onChange={onChange}
            />
        </div>
    );
};

export default ProfilePictureManagement;
