import React from "react";
import "./SocialMediaGrid.scss";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    HatenaIcon,
    HatenaShareButton,
    InstapaperIcon,
    InstapaperShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    LivejournalIcon,
    LivejournalShareButton,
    MailruIcon,
    MailruShareButton,
    OKIcon,
    OKShareButton,
    PinterestIcon,
    PinterestShareButton,
    PocketIcon,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    VKIcon,
    VKShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    WorkplaceShareButton,
} from "react-share";

interface SocialMediaShareModalProps {
    url: string;
}

const SocialMediaGrid = ({ url }: SocialMediaShareModalProps) => {
    const className = "share-button";
    const props = {
        className,
        url,
    };
    return (
        <div className={"social-media-grid"}>
            <div className={"button-grid"}>
                <EmailShareButton {...props}>
                    <EmailIcon />
                </EmailShareButton>
                <FacebookShareButton {...props}>
                    <FacebookIcon />
                </FacebookShareButton>
                <FacebookMessengerShareButton
                    {...props}
                    appId={"521270401588372"}
                >
                    <FacebookMessengerIcon />
                </FacebookMessengerShareButton>
                <WhatsappShareButton {...props}>
                    <WhatsappIcon />
                </WhatsappShareButton>
                <TwitterShareButton {...props}>
                    <TwitterIcon />
                </TwitterShareButton>
                <LinkedinShareButton {...props}>
                    <LinkedinIcon />
                </LinkedinShareButton>
                <TumblrShareButton {...props}>
                    <TumblrIcon />
                </TumblrShareButton>
                <LineShareButton {...props}>
                    <LineIcon />
                </LineShareButton>
                <TelegramShareButton {...props}>
                    <TelegramIcon />
                </TelegramShareButton>
                <RedditShareButton {...props}>
                    <RedditIcon />
                </RedditShareButton>
                <HatenaShareButton {...props}>
                    <HatenaIcon />
                </HatenaShareButton>
                <InstapaperShareButton {...props}>
                    <InstapaperIcon />
                </InstapaperShareButton>
                <LivejournalShareButton {...props}>
                    <LivejournalIcon />
                </LivejournalShareButton>
                <MailruShareButton {...props}>
                    <MailruIcon />
                </MailruShareButton>
                <OKShareButton {...props}>
                    <OKIcon />
                </OKShareButton>
                <PinterestShareButton {...props} media={""}>
                    <PinterestIcon />
                </PinterestShareButton>
                <PocketShareButton {...props}>
                    <PocketIcon />
                </PocketShareButton>
                <ViberShareButton {...props}>
                    <ViberIcon />
                </ViberShareButton>
                <VKShareButton {...props}>
                    <VKIcon />
                </VKShareButton>
                <WorkplaceShareButton {...props}>
                    <WorkplaceIcon />
                </WorkplaceShareButton>
            </div>
        </div>
    );
};

export default SocialMediaGrid;
