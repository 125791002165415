import axios, { AxiosResponse } from "axios";
import { RecruiterInfo, RecruiterPublicInfo } from "./types";
import storage from "../common/storage";
import { CORE_API, STORAGE_API_URL } from "../env";

const spondJWTTokenName = "token";

export const api = {
    V2_1: {
        referral: {
            referrals: `${CORE_API}/referral/referrals`,
            recruiters: {
                referralCode: (referralCode: string) =>
                    `${CORE_API}/referral/recruiters/${referralCode}`,
                me: `${CORE_API}/referral/recruiters/me`,
            },
        },
        applink: {
            refcode: `${CORE_API}/applink/referral_program?store=play`,
        },
        logIn: `${CORE_API}/login`,
        logOut: `${CORE_API}/logout`,
        images: {
            upload: `${STORAGE_API_URL}/images/upload`,
        },
        geolocation: `${CORE_API}/geolocation`,
    },
};

export function updateRecruiterProfilePicture(
    profilePictureUrl: string | null
): Promise<AxiosResponse<void>> {
    return axios.patch(
        api.V2_1.referral.recruiters.me,
        {
            imageUrl: profilePictureUrl,
        },
        {
            headers: {
                Authorization: `Bearer ${storage.getItem(spondJWTTokenName)}`,
            },
        }
    );
}

export type MultiPartFormDataResponse = {
    url?: string;
    name?: string;
    length?: number;
    width?: number;
    height?: number;
}[];

export function uploadImage(
    image: File
): Promise<AxiosResponse<MultiPartFormDataResponse>> {
    const formData = new FormData();
    formData.append("file", image);
    return axios.post(api.V2_1.images.upload, formData, {
        headers: {
            Authorization: `Bearer ${storage.getItem(spondJWTTokenName)}`,
            "Content-Type": "multipart/form-data",
        },
    });
}

export function linkToStore(
    abortController: AbortController
): Promise<AxiosResponse<RecruiterPublicInfo>> {
    return axios.get(api.V2_1.applink.refcode, {
        signal: abortController.signal,
    });
}

export function fetchRecruiterPublicInfoByReferralCode(
    referralCode: string,
    abortController: AbortController
): Promise<AxiosResponse<RecruiterPublicInfo>> {
    return axios.get(api.V2_1.referral.recruiters.referralCode(referralCode), {
        signal: abortController.signal,
    });
}

export function fetchRecruiterInfoByReferralCode(
    abortController: AbortController
): Promise<AxiosResponse<RecruiterInfo>> {
    return axios.get(api.V2_1.referral.recruiters.me, {
        headers: {
            Authorization: `Bearer ${storage.getItem(spondJWTTokenName)}`,
        },
        signal: abortController.signal,
    });
}

export function postSignUp(
    referralCode: string | undefined,
    email: string
): Promise<AxiosResponse> {
    return axios.post(api.V2_1.referral.referrals, {
        referralCode,
        email: email,
    });
}

export function fetchCountryCode(): Promise<
    AxiosResponse<string | null | object>
> {
    return axios.get(api.V2_1.geolocation);
}

export enum LogInMethod {
    PHONE,
    EMAIL,
}

export function postLogIn(
    logInMethod: LogInMethod,
    data: {
        phoneNumber?: string;
        email?: string;
        password: string;
    }
): Promise<AxiosResponse<{ loginToken: string }>> {
    const isPhone = logInMethod === LogInMethod.PHONE;
    return axios
        .post(api.V2_1.logIn, {
            [isPhone ? "phoneNumber" : "email"]: isPhone
                ? data.phoneNumber
                : data.email,
            password: data.password,
        })
        .then((res: AxiosResponse<{ loginToken: string }>) => {
            storage.setItem(spondJWTTokenName, res.data.loginToken);
            return res;
        });
}

export function postLogOut() {
    return axios
        .post(api.V2_1.logOut, undefined, {
            headers: {
                Authorization: `Bearer ${storage.getItem(spondJWTTokenName)}`,
            },
        })
        .finally(() => {
            storage.removeItem(spondJWTTokenName);
        });
}
