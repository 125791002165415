import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const fallbackLng = "en";

export interface Language {
    name: string;
    code: string;
}

export const languages: Language[] = [
    {
        name: "English",
        code: fallbackLng,
    },
    {
        name: "Deutsch",
        code: "de",
    },
    {
        name: "Nederlands",
        code: "nl",
    },
];
const supportedLngs = languages.map((language) => language.code);
i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: {
            default: ["en"],
        },
        ns: ["referral", "common", "recruiter"],
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        react: {
            useSuspense: false,
        },
        detection: {
            order: ["querystring", "navigator"],
            caches: [],
            lookupQuerystring: "lng",
        },
        supportedLngs,
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });
