import React, { FunctionComponent, SVGProps } from "react";
import Card from "../../../components/card/Card";
import "./IsNotRecruiterErrorPage.scss";
import { ReactComponent as BoxArrowRight } from "../../../assets/icons/bootstrap/box-arrow-right.svg";
import { ReactComponent as Arrow90DegRight } from "../../../assets/icons/bootstrap/arrow-90deg-right.svg";
import Button from "../../../components/button/Button";
import { postLogOut } from "../../../api/interface";
import IconText from "../../../components/icon/IconText";
import { Trans, useTranslation } from "react-i18next";

type SuggestionCardProps = {
    myText?: string;
    buttonOnClick: () => void;
    buttonText: string;
    text: string;
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
};
const SuggestionCard = ({
    text,
    buttonOnClick,
    Icon,
    buttonText,
}: SuggestionCardProps) => {
    return (
        <Card className={"inr-card"}>
            <div className={"inr-card-content"}>
                <h3>{text}</h3>
                <Button
                    className={"inr-button"}
                    level={"alternative"}
                    onClick={buttonOnClick}
                >
                    <IconText LeftIcon={Icon} text={buttonText} />
                </Button>
            </div>
        </Card>
    );
};

const IsNotRecruiterErrorPage = ({ onLogOut }: { onLogOut: () => void }) => {
    const { t } = useTranslation("recruiter", {
        keyPrefix: "not-recruiter",
    });
    return (
        <div id={"is-not-recruiter-error-page"}>
            <div id={"inr-banner"}>
                <div id={"inr-constrict"}>
                    <h1 id={"inr-heading"}>{t("title")}</h1>
                    <h4 id={"inr-description"}>{t("explanation")}</h4>
                </div>
            </div>
            <div id="inr-container">
                <div id={"inr-split"}>
                    <SuggestionCard
                        buttonText={t("button_log-out")}
                        Icon={BoxArrowRight}
                        text={t("suggestion_wrong-account")}
                        buttonOnClick={() => postLogOut().finally(onLogOut)}
                    />
                    <SuggestionCard
                        Icon={Arrow90DegRight}
                        buttonText={t("button_my-spond")}
                        text={t("suggestion_my-spond")}
                        buttonOnClick={() =>
                            (window.location.href = "https://spond.com/client/")
                        }
                    />
                </div>
            </div>
            <p id={"inr-contact-us"}>
                <Trans t={t} i18nKey={"suggestion_none-of-the-above"}>
                    {/*Note: The following text looks hard coded but is NOT and the misspelling herein does not show in production.*/}
                    {/*The text is intentionally slightly misspelled here so that the tests will fail if localization fails.*/}
                    (Error) If this does not seem right, please{" "}
                    <a tabIndex={0} href={"mailto:feedback@spond.com"}>
                        contact us
                    </a>
                    , so we can look into the problem.
                </Trans>
            </p>
        </div>
    );
};

export default IsNotRecruiterErrorPage;
