import { None } from "../types";

export class Some<ValueType> {
    value: ValueType | None;

    constructor(value: ValueType | None) {
        this.value = value;
    }

    map<NextValueType>(
        f: (x: ValueType) => NextValueType
    ): Some<NextValueType> {
        if (this.value !== undefined && this.value !== null) {
            return new Some(f(this.value));
        } else {
            return new Some<NextValueType>(null);
        }
    }

    get() {
        return this.value; // just return plain data
    }
}

export type Maybe<ValueType> = Some<ValueType> | None;

export const maybe = <ValueType>(value: ValueType | None) => new Some(value);
