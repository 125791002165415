import Logo from "../logo/Logo";
import personIcon from "../../assets/icons/person.svg";
import React from "react";
import "./ProfilePicture.scss";
import { ReactComponent as CameraIcon } from "../../assets/icons/camera.svg";
import { classNames } from "../../common/utils/utils";

type ProfilePictureProps = {
    imageUrl?: string | null;
    showEditIcon?: boolean;
} & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

const EditProfilePictureIcon = () => {
    return (
        <div className={"pp-edit-container"}>
            <div className={"pp-edit"}>
                <CameraIcon />
            </div>
        </div>
    );
};

export const ProfilePictureOrTorso = ({
    imageUrl,
    showEditIcon,
    ...props
}: ProfilePictureProps) => {
    const className = classNames(
        { cn: "profile-picture", condition: true },
        {
            cn: props.className,
            condition: !!props.className,
        },
        {
            cn: "disabled",
            condition: props.disabled || !props.onClick,
        }
    );
    const networkInvariableUrl = imageUrl?.includes("localhost")
        ? imageUrl?.split("localhost").pop()
        : imageUrl;
    return (
        <button className={className} {...props}>
            {imageUrl ? (
                <Logo src={networkInvariableUrl!} alt={"Your logo"} />
            ) : (
                <div className={"pp-profile-image-upload"}>
                    <div
                        className={"pp-icon-container"}
                        title={"Your profile picture will be visible here."}
                    >
                        <img
                            className={"pp-person-icon"}
                            src={personIcon}
                            alt={"person silhouette, no profile picture"}
                        />
                    </div>
                </div>
            )}
            {showEditIcon && <EditProfilePictureIcon />}
        </button>
    );
};
