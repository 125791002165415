import { MonetaryValue, RecruiterInfo } from "../../../../../api/types";
import React from "react";
import { payoutRow } from "./common";
import { TableRow } from "../../../../../components/table/Table";
import ReferralsListCard from "./ReferralsListCard";
import { useTranslation } from "react-i18next";

const PayoutsListCard = ({
    recruiterInfo,
}: {
    recruiterInfo: RecruiterInfo;
}) => {
    const pendingTranslation = useTranslation("common").t(
        "payout-status.PENDING"
    );
    const rows: TableRow[] = recruiterInfo?.recruiterPrivateInfo?.payouts?.map(
        (payout: MonetaryValue) => ({
            fields: payoutRow(payout, pendingTranslation),
        })
    );
    const { t } = useTranslation("recruiter", {
        keyPrefix: "stats-page.cards.payouts",
    });

    return (
        <ReferralsListCard
            title={t("title")}
            rows={rows}
            text={t("explanation")}
        />
    );
};

export default PayoutsListCard;
