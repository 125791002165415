import React, { ForwardedRef, RefObject } from "react";
import Modal, { ModalProps } from "../basic/Modal";
import xIcon from "./modalXIcon.svg";
import "./TitledModal.scss";

type TitledModalProps = { title: string; top?: boolean } & ModalProps;

const TitledModal = React.forwardRef(
    (
        { id, title, children, className, top, ...props }: TitledModalProps,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        return (
            <Modal
                id={id}
                className={`titled-modal ${className ? className : ""} ${
                    top ? "top" : ""
                }`}
                ref={ref}
                {...props}
            >
                <div className="modal-title-section">
                    <div className={"modal-title-left"} />
                    <h2 className={"modal-title"}>{title}</h2>
                    <button
                        onClick={() => {
                            (ref as RefObject<HTMLDivElement>).current?.click();
                        }}
                        tabIndex={0}
                        className={"modal-x-button"}
                    >
                        <img src={xIcon} alt={"close modal x"} />
                    </button>
                </div>
                <div className={"modal-content-section"}>{children}</div>
            </Modal>
        );
    }
);
export default TitledModal;
