import React from "react";
import { useTranslation } from "react-i18next";
import GenericErrorPage from "../../error/generic-error-page/GenericErrorPage";

const NoReferralCodeFallback = ({}) => {
    const { t } = useTranslation("referral", {
        keyPrefix: "error-page.404-missing-referral-code",
    });
    return (
        <GenericErrorPage
            title={t("subheading_referral-code-missing")}
            text={t("text_suggestion-for-resolving-issue")}
        />
    );
};

export default NoReferralCodeFallback;
