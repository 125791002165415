import React from "react";
import SpondLogo from "../logo/spond/SpondLogo";
import { useTranslation } from "react-i18next";

import "./SignupPageFooter.scss";

const SignupPageFooter = () => {
    const { t } = useTranslation("common", { keyPrefix: "footer" });
    return (
        <footer>
            <SpondLogo />
            <small>{t("text")}</small>
            <div className={"footer-row"}>
                <a className={"field"} href={"https://spond.com/terms"}>
                    {t("link_terms")}
                </a>
                <a className={"field"} href={"https://spond.com/privacy"}>
                    {t("link_privacy")}
                </a>
                <a className={"field"} href={"https://spond.com/cookies"}>
                    {t("link_cookies")}
                </a>
            </div>
            <small>{t("address")}</small>
        </footer>
    );
};

export default SignupPageFooter;
