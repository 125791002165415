import React from "react";

export interface TableField {
    id?: string;
    className?: string;
    children?: React.ReactNode;
}

export interface TableRow {
    fields: TableField[];
}

export interface TableData {
    rows: TableRow[];
}

const Table = ({ data }: { data: TableData }) => {
    return (
        <table>
            <tbody>
                {data.rows.map((row, rowId) => {
                    return (
                        <tr key={rowId}>
                            {row.fields.map((field: TableField, fieldId) => {
                                return <td {...field} key={fieldId} />;
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default Table;
