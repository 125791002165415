import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import PhoneNumberInput, {
    fullPhoneNumber,
    PhoneInputTranslations,
    PhoneNumber,
} from "../../../components/phone-number/PhoneNumberInput";
import { useTranslation } from "react-i18next";
import SpondLogo from "../../../components/logo/spond/SpondLogo";
import Button from "../../../components/button/Button";
import ButtonGroup from "../../../components/button-group/ButtonGroup";
import { maybe } from "../../../library/optional-chaining/maybe";
import { LogInMethod, postLogIn } from "../../../api/interface";
import {
    isValidPhoneNumber,
    parsePhoneNumberFromString,
} from "libphonenumber-js";
import Input from "../../../components/input/Input";
import "./RecruiterLogInPage.scss";

interface LogInForm {
    email?: string;
    phoneNumber?: string;
    password: string;
}

const inputTypes = [
    {
        index: LogInMethod.PHONE,
        id: "phone",
        textKey: "button_phone",
    },
    {
        index: LogInMethod.EMAIL,
        id: "email",
        textKey: "button_email",
    },
];

const RecruiterLogInPage = ({
    defaultCountryCode,
    onLogIn,
}: {
    defaultCountryCode: string;
    onLogIn: () => void;
}) => {
    const [inputType, setInputType] = useState(LogInMethod.PHONE);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        clearErrors,
    } = useForm({
        defaultValues: {
            phoneNumber: fullPhoneNumber({
                countryCode: defaultCountryCode,
                number: "",
            }),
            email: "",
            password: "",
        },
        reValidateMode: "onSubmit",
        shouldFocusError: false,
    });

    // TODO do something with translation namespace?
    const tCommonPhone = useTranslation("common", {
        keyPrefix: "phone",
    }).t;
    const tRecruiterSignIn = useTranslation("recruiter", {
        keyPrefix: "sign-in",
    }).t;
    const tPhone: PhoneInputTranslations = useTranslation("common").t(
        "phone-field",
        { returnObjects: true }
    );

    const [backendError, setBackendError] = useState<string | null>(null);
    const clearBackendError = () => setBackendError(null);

    const onSubmit: SubmitHandler<any> = (data: LogInForm, event) => {
        event?.preventDefault();
        postLogIn(inputType, data)
            .then(onLogIn)
            .catch((error) => {
                const status = error?.response?.status;
                if (status === 401) {
                    setBackendError(
                        inputType === LogInMethod.PHONE
                            ? "error_log-in-phone-credentials-invalid"
                            : "error_log-in-email-credentials-invalid"
                    );
                } else {
                    setBackendError("error_log-in-failed-unexpectedly");
                }
            });
    };

    register("phoneNumber", {
        required:
            inputType === LogInMethod.PHONE && "error_phone-number-missing",
        onChange: (phoneNumber: string) => setValue("phoneNumber", phoneNumber),
        validate: (phoneNumber: string) => {
            const number = parsePhoneNumberFromString(phoneNumber)?.number;
            if (inputType === LogInMethod.PHONE) {
                if (number === null || number === undefined)
                    return "error_phone-number-missing";
                return !isValidPhoneNumber(phoneNumber)
                    ? "error_phone-number-invalid"
                    : undefined;
            }
        },
    });

    const ChooseLogInMethod = (
        <ButtonGroup
            id={"rli-login-method"}
            defaultSelectedIndex={0}
            data={inputTypes.map(({ textKey, ...rest }) => ({
                ...rest,
                children: tRecruiterSignIn(`choose-login-method.${textKey}`),
            }))}
            onChange={(index: LogInMethod) => setInputType(index)}
        />
    );

    const PhoneInput = (
        <PhoneNumberInput
            id={"phone-input"}
            className={errors?.phoneNumber?.message}
            translate={tPhone}
            defaultCountryCode={defaultCountryCode}
            onChange={(phone: PhoneNumber) => {
                clearBackendError();
                clearErrors("phoneNumber");
                setValue("phoneNumber", fullPhoneNumber(phone));
            }}
            error={maybe(errors?.phoneNumber?.message)
                .map((it) => tCommonPhone(it))
                .get()}
        />
    );

    const EmailInput = (
        <Input
            id={"email-input"}
            enterKeyHint="enter"
            title={tRecruiterSignIn("title_email")}
            maxLength={255}
            type="email"
            inputMode="text"
            placeholder={tRecruiterSignIn("placeholder_email")}
            {...register("email", {
                required:
                    inputType === LogInMethod.EMAIL &&
                    tRecruiterSignIn("error_email-missing").toString(),
                onChange: (e) => {
                    clearBackendError();
                    clearErrors("email");
                    setValue("email", e.target.value);
                },
            })}
            error={errors?.email?.message}
        />
    );

    const EmailOrPhoneInputComponent: React.ReactNode =
        inputType === LogInMethod.PHONE ? PhoneInput : EmailInput;

    const PasswordInput = (
        <Input
            id={"rli-password"}
            placeholder={tRecruiterSignIn("placeholder_password")}
            type={"password"}
            title={tRecruiterSignIn("title_password")}
            error={errors?.password?.message}
            {...register("password", {
                required: tRecruiterSignIn("error_password-missing").toString(),
                onChange: (e) => {
                    clearBackendError();
                    clearErrors("password");
                    setValue("password", e.target.value);
                },
            })}
        />
    );

    const LogInButton = (
        <Button
            id={"rli-submit"}
            type={"submit"}
            title={tRecruiterSignIn("button-title_login")}
        >
            {tRecruiterSignIn("button_login")}
        </Button>
    );

    return (
        <main id={"recruiter-log-in"}>
            <div id={"content"}>
                <SpondLogo />
                <div id={"rli-area"}>
                    {ChooseLogInMethod}
                    <form id={"rli-form"} onSubmit={handleSubmit(onSubmit)}>
                        {EmailOrPhoneInputComponent}
                        {PasswordInput}
                        {LogInButton}
                    </form>
                    {backendError && (
                        <p className={"error"}>
                            {tRecruiterSignIn(backendError)}
                        </p>
                    )}
                </div>
            </div>
        </main>
    );
};

export default RecruiterLogInPage;
