import React from "react";
import GenericErrorPage from "../../error/generic-error-page/GenericErrorPage";
import { useTranslation } from "react-i18next";

const ErrorOccurredPage = () => {
    const tErrorPage = useTranslation("referral", {
        keyPrefix: "error-page.generic",
    }).t;
    return (
        <GenericErrorPage
            title={tErrorPage("subheading_error-title")}
            text={tErrorPage("text_error-description")}
        />
    );
};

export default ErrorOccurredPage;
