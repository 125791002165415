import { ReactComponent as Globe } from "../../../../assets/icons/bootstrap/globe.svg";
import React, { RefObject, useRef } from "react";
import "./LanguageManagement.scss";
import LanguageChanger from "../../../../components/common/language-changer/LanguageChanger";
import TitledModal from "../../../../components/modal/titled/TitledModal";
import { languages } from "../../../../internationalization/i18n";

const LanguageManagement = ({ id }: { id?: string }) => {
    const ref: RefObject<HTMLDivElement> = useRef(null);
    return (
        <div className={"language-bar"}>
            <div id={id} className={"lb-content"}>
                <button
                    className={"lb-change-language"}
                    onClick={() => {
                        ref.current?.click();
                    }}
                >
                    <Globe className={"lb-globe-icon"} />
                </button>
            </div>
            <TitledModal
                top
                title={"Language"}
                className={"lb-modal"}
                ref={ref}
            >
                <LanguageChanger
                    languages={languages}
                    flat
                    onChange={() => setTimeout(() => ref.current?.click(), 450)}
                />
            </TitledModal>
        </div>
    );
};

export default LanguageManagement;
