import React, { DetailedHTMLProps, HTMLAttributes, useState } from "react";
import "./ButtonGroup.scss";

type Option<T> = { index: T } & DetailedHTMLProps<
    HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

interface ButtonGroupProps<T> {
    id?: string;
    className?: string;
    data: Option<T>[];
    defaultSelectedIndex?: number;
    onChange: (index: T) => void;
}

const ButtonGroup = <T extends any>({
    id,
    data,
    defaultSelectedIndex,
    onChange,
    className,
}: ButtonGroupProps<T>) => {
    const [selected, setSelected] = useState<number>(defaultSelectedIndex || 0);
    if (data.length === 0) return null;
    return (
        <div
            id={id}
            className={`spond-button-group ${className || ""}`}
            tabIndex={-1}
        >
            {data.map((option, index) => {
                const isSelected = index === selected;
                return (
                    <button
                        tabIndex={0}
                        {...option}
                        key={`button-group-option-${index}`}
                        id={option.id}
                        className={`sbg-option ${
                            isSelected ? "selected" : ""
                        } ${option.className || ""}`}
                        onClick={() => {
                            onChange(option.index);
                            index !== selected && setSelected(index);
                        }}
                    >
                        {option.children}
                    </button>
                );
            })}
        </div>
    );
};

export default ButtonGroup;
