import Logo from "../Logo";
import SpondLogo from "./SpondLogo";
import React from "react";

const ImageOrSpondLogo = ({ imageUrl }: { imageUrl?: string | null }) => {
    const url = imageUrl
        ?.replace("http://localhost", "")
        ?.replace("https://localhost", "");

    return url ? <Logo src={url} alt={"Recruiter logo"} /> : <SpondLogo />;
};

export default ImageOrSpondLogo;
