import React, { useRef, useState } from "react";
import Card from "../../../../../components/card/Card";
import Button from "../../../../../components/button/Button";
import TitledModal from "../../../../../components/modal/titled/TitledModal";
import SocialMediaGrid from "../../social-media-modal/SocialMediaGrid";
import {
    copyDirectlyToClipboardOrByRef,
    shareNatively,
} from "../../../../../common/utils/utils";
import { ReactComponent as Files } from "../../../../../assets/icons/bootstrap/files.svg";
import { ReactComponent as Share } from "../../../../../assets/icons/bootstrap/share-fill.svg";
import "./ReferralCodeCard.scss";
import "../card-styles.scss";
import IconText from "../../../../../components/icon/IconText";
import { useTranslation } from "react-i18next";
import { useStateWhileMounted } from "../../../../../common/hooks";

const SHARE_MESSAGE = "message_share";
const FAILED_TO_COPY_MESSAGE_KEY = "error_failed-to-copy";

function useCopyToClipboard(text: string) {
    const [errorMessage, setErrorMessage] = useState("");
    const linkSpanElementRef: React.RefObject<HTMLSpanElement> = useRef(null);
    const copyToClipboard = () =>
        copyDirectlyToClipboardOrByRef(text, linkSpanElementRef).catch(() =>
            setErrorMessage(FAILED_TO_COPY_MESSAGE_KEY)
        );
    return { errorMessage, copyToClipboard, linkSpanElementRef };
}

const ReferralCodeCard = ({ referralCode }: { referralCode: string }) => {
    const url = `https://referral.spond.com/${referralCode}`;
    const [showMessage, setShowMessage] = useStateWhileMounted(false);
    const showMessageTemporarily = () => {
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 2000);
    };
    const { errorMessage, copyToClipboard, linkSpanElementRef } =
        useCopyToClipboard(url);
    const modalRef = useRef<HTMLDivElement>(null);
    const openModal = () => modalRef.current?.click();
    const { t } = useTranslation("recruiter", {
        keyPrefix: "stats-page.cards.your-referral",
    });

    function shareNativelyOrOpenModalWithShareButtons() {
        shareNatively(url, t(SHARE_MESSAGE)).catch(() => openModal());
    }

    return (
        <Card
            tabIndex={0}
            id={"referral-code-card"}
            className={"rsp-card-styling card-content"}
        >
            <h2 className={"card-title-variant-1"}>{t("title")}</h2>
            <div
                id={"rcc-display"}
                className={
                    showMessage
                        ? errorMessage
                            ? "show-copied error"
                            : "show-copied"
                        : undefined
                }
            >
                <span id={"link-span"} ref={linkSpanElementRef}>
                    {showMessage
                        ? errorMessage
                            ? t(errorMessage)
                            : t("message_copied-to-clipboard")
                        : url}
                </span>
            </div>
            <Button
                id={"rcc-copy-button"}
                className={showMessage ? "show-copied" : undefined}
                data-copy={"#rcc-display"}
                data-done={"SECTION COPIED"}
                onClick={() => {
                    copyToClipboard().then(showMessageTemporarily);
                }}
            >
                <IconText LeftIcon={Files} text={t("button_copy")} />
            </Button>
            <Button
                id={"rcc-share-button"}
                onClick={shareNativelyOrOpenModalWithShareButtons}
            >
                <IconText LeftIcon={Share} text={t("button_share")} />
            </Button>
            <TitledModal
                id={"rcc-share-modal"}
                title={t("modal-title_share")}
                ref={modalRef}
            >
                <SocialMediaGrid url={url} />
            </TitledModal>
        </Card>
    );
};

export default ReferralCodeCard;
