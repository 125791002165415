import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { RecruiterPublicInfo } from "../../api/types";
import { fetchRecruiterPublicInfoByReferralCode } from "../../api/interface";
import { AxiosError } from "axios";

export function useReferralCodeToFetchRecruiterInfo() {
    const { referralCode } = useParams();
    const [recruiter, setRecruiter] = useState<RecruiterPublicInfo | null>(
        null
    );
    const [recruiterError, setRecruiterError] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const abortController = new AbortController();
        const abortOnUnmount = () => abortController.abort();
        referralCode &&
            fetchRecruiterPublicInfoByReferralCode(
                referralCode,
                abortController
            )
                .then((result) => {
                    setRecruiter(result.data);
                    setRecruiterError(null);
                    setIsLoading(false);
                })
                .catch((error: AxiosError) => {
                    setRecruiterError(error.response?.status || null);
                    setIsLoading(false);
                });
        return abortOnUnmount;
    }, [referralCode]);
    return {
        recruiter,
        recruiterError,
        referralCode,
        isLoading,
    };
}
