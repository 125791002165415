import React, { DetailedHTMLProps, HTMLAttributes, useState } from "react";
import { postSignUp } from "../../../api/interface";
import SimpleCard from "../../../components/card/SimpleCard";
import { reasonsToUseSpond } from "./data";
import Button from "../../../components/button/Button";
import { Trans, useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import SignupPageFooter from "../../../components/common/SignupPageFooter";
import {
    FieldErrors,
    FieldValues,
    SubmitHandler,
    useForm,
} from "react-hook-form";
import "./SignUpPage.scss";

import { RecruiterPublicInfo } from "../../../api/types";
import ImageOrSpondLogo from "../../../components/logo/spond/ImageOrSpondLogo";
import LanguageManagement from "./language/LanguageManagement";
import { useNavigate } from "react-router-dom";
import { useStateWhileMounted } from "../../../common/hooks";
import { classNames } from "../../../common/utils/utils";
import ReactPlayer from "react-player";
import Input from "../../../components/input/Input";

type EmailForm = { email: string };
const useBackendEmailSubmissionAndErrors = (
    errors: FieldErrors<EmailForm>,
    referralCode: string
) => {
    const [submissionError, setSubmissionError] = useState<string | null>(null);
    const [submissionSuccess, setSubmissionSuccess] =
        useStateWhileMounted(false);
    const navigate = useNavigate();
    const onSubmit: SubmitHandler<FieldValues> = (data, e) => {
        e?.preventDefault();
        if (!submissionError && !errors?.email) {
            console.log(data);
            postSignUp(referralCode, data.email)
                .then((_) => {
                    setSubmissionSuccess(true);
                    navigate("/referral/redirect");
                })
                .catch((e: AxiosError) => {
                    if (e.response?.status === 400) {
                        setSubmissionError("email.error_email-invalid");
                    } else {
                        setSubmissionError(
                            "email.error_sign-up-failed-unexpectedly"
                        );
                    }
                });
        }
    };
    return {
        onSubmit,
        submissionError,
        clearBackendError: () => setSubmissionError(null),
        submissionSuccess,
    };
};

const Center = ({
    className,
    ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
    <div className={classNames(className, "su-center")} {...props} />
);

const SignUpPage = ({
    recruiter: {
        displayName,
        referralCode,
        optionalData: { videoUrl, imageUrl, customHeadingKey },
    },
}: {
    recruiter: RecruiterPublicInfo;
}) => {
    const { t } = useTranslation("referral", {
        keyPrefix: "sign-up",
    });
    const { t: tCommon } = useTranslation("common");
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm<EmailForm>({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        shouldFocusError: false,
    });
    const { onSubmit, submissionError, clearBackendError } =
        useBackendEmailSubmissionAndErrors(errors, referralCode);
    const headingInvitationToSpond =
        customHeadingKey || "heading_invitation_to_spond";

    const errorToShow: string | null | undefined =
        submissionError || errors.email?.message;

    const Email = (
        <Input
            className={errorToShow ? "error" : ""}
            enterKeyHint={"enter"}
            title={tCommon("email.title")}
            maxLength={255}
            type={"text"}
            placeholder={tCommon("email.placeholder")}
            {...register("email", {
                required: tCommon("email.error_email-missing"),
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: tCommon("email.error_email-invalid"),
                },
                onChange: (e) => {
                    clearErrors();
                    clearBackendError();
                },
            })}
        ></Input>
    );

    const SignUpButton = (
        <Button id="su-sign-up-button" type={"submit"}>
            {t("button_sign_up")}
        </Button>
    );

    const CardsWithReasonsToUseSpond = reasonsToUseSpond
        .map(({ icon, title, text }) => ({
            icon,
            title: t(title),
            text: t(text),
        }))
        .map((it, i) => (
            <SimpleCard
                key={`reason-${i}`}
                icon={it.icon}
                title={it.title}
                text={it.text}
            />
        ));

    return (
        <div id={"sign-up-page"}>
            <LanguageManagement />
            <main id={"su-content"}>
                <Center>
                    <ImageOrSpondLogo imageUrl={imageUrl} />
                    <h1>{t(headingInvitationToSpond, { displayName })}</h1>
                    <h3>{t("subheading_description_of_spond")}</h3>
                    <form
                        id={"su-form"}
                        onSubmit={handleSubmit(onSubmit)}
                        data-testid={"form"}
                    >
                        {Email}
                        {SignUpButton}
                    </form>
                    {errorToShow && (
                        <p
                            id={"su-phone-validation-error"}
                            data-testid={"error"}
                        >
                            {tCommon(errorToShow)}
                        </p>
                    )}
                    <p id="su-privacy-p">{t("caption_no_adds")}</p>
                    <small id="su-privacy-sm">
                        <Trans t={t} i18nKey={"text_user-privacy-description"}>
                            {/*Note: The following text looks hard coded but is NOT and does not show in production.*/}
                            {/*The text is intentionally slightly misspelled here so that the tests will fail if localization fails.*/}
                            (Error) We collect your phone number and match it to
                            your new profile when you register in Spond. By
                            signing up you consent to Spond processing your
                            data. For further information please refer to our{" "}
                            <a href={"https://spond.com/privacy"}>
                                privacy policy
                            </a>
                            .
                        </Trans>
                    </small>
                </Center>
                {videoUrl && (
                    <>
                        <Center>
                            <div id="su-horizontal-line" />
                            <h2 id="su-video-title">
                                <Trans t={t} i18nKey="subheading_video">
                                    {/*Note: The following text looks hard coded but is NOT and does not show in production.*/}
                                    {/*The text is intentionally slightly misspelled here so that the tests will fail if localization fails.*/}
                                    (Error) Hear what one of <b>asdf</b> of
                                    Dutch clubs thinks about Spond:
                                </Trans>
                            </h2>
                        </Center>
                        <ReactPlayer
                            id={"su-teamers-video"}
                            controls
                            url={videoUrl}
                        />
                    </>
                )}
                <Center>
                    <div id="su-horizontal-line" />
                    <h2 id="su-why-use-spond">
                        <Trans
                            t={t}
                            i18nKey="why-use-spond.heading_encouragement-to-read-further"
                        >
                            {/*Note: The following text looks hard coded but is NOT and does not show in production.*/}
                            {/*The text is intentionally slightly misspelled here so that the tests will fail if localization fails.*/}
                            (Error) Read about why Spond is trusted by{" "}
                            <b>1 million</b>
                            coaches parents and players.
                        </Trans>
                    </h2>
                    {CardsWithReasonsToUseSpond}
                    <SignupPageFooter />
                </Center>
            </main>
        </div>
    );
};

export default SignUpPage;
