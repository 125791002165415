import { RecruiterInfo } from "../../../../../api/types";
import React from "react";
import { payoutRow } from "./common";
import { TableRow } from "../../../../../components/table/Table";
import ReferralsListCard from "./ReferralsListCard";
import { useTranslation } from "react-i18next";

const SuccessfulReferralsCard = ({
    recruiterInfo,
}: {
    recruiterInfo: RecruiterInfo;
}) => {
    const pendingTranslation = useTranslation("common").t(
        "payout-status.PENDING"
    );
    const { t } = useTranslation("recruiter", {
        keyPrefix: "stats-page.cards.successful-referrals",
    });
    const rows: TableRow[] =
        recruiterInfo.recruiterPrivateInfo.successfulReferrals.map(
            (payout) => ({
                fields: payoutRow(payout, pendingTranslation),
            })
        );
    return <ReferralsListCard title={t("title")} rows={rows} />;
};

export default SuccessfulReferralsCard;
