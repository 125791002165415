import { isAndroid, isIOS } from "react-device-detect";
import React from "react";

export const linkToStore = () => {
    if (isIOS) {
        return "https://itunes.apple.com/us/app/spond/id755596884?pt=2029396&ls=1&mt=8&ct=referral_program";
    } else if (isAndroid) {
        return "https://play.google.com/store/apps/details?id=com.spond.spond&referrer=utm_source=referral_program";
    }
    return null;
};

export const formatCurrency = (
    cents: number,
    currency: string,
    language: string | null
) =>
    cents.toLocaleString(language ?? undefined, {
        style: "currency",
        currency: currency || undefined,
        useGrouping: true,
    });

export function copyDirectlyToClipboard(text: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const result = navigator?.clipboard
            ?.writeText?.(text)
            .then((_) => resolve(true))
            .catch((_) => reject(false));
        if (!result) {
            reject(false);
        }
    });
}

export function copyHTMLElementContentToClipboard(
    linkRef: React.RefObject<HTMLSpanElement>
): boolean {
    const currentNode = linkRef?.current;
    const selection = window.getSelection();
    if (currentNode && selection) {
        const range = document.createRange();
        range.selectNode(currentNode);
        range.selectNodeContents(currentNode);
        selection.removeAllRanges();
        selection.addRange(range);
        if (document?.execCommand?.("copy")) {
            selection.removeAllRanges();
            return true;
        }
    }
    return false;
}

export function copyDirectlyToClipboardOrByRef(
    text: string,
    ref: React.RefObject<any>
): Promise<string | null> {
    return new Promise((resolve, reject) => {
        try {
            if (copyHTMLElementContentToClipboard(ref)) {
                return resolve(null);
            } else {
                return copyDirectlyToClipboard(text)
                    .then((_) => {
                        resolve(null);
                    })
                    .catch((_) => {
                        reject();
                    });
            }
        } catch (e) {
            reject();
        }
    });
}

export function shareNatively(url: string, shareMessage: any): Promise<void> {
    if (!!navigator.canShare && navigator.canShare({ url })) {
        return navigator.share({ url, text: shareMessage });
    }
    return new Promise((resolve, reject) => reject());
}

export const isObject = (arg: any) => typeof arg === "object";

export const hasValue = (arg?: any | null) => arg !== null && arg !== undefined;

type ClassNameCondition =
    | { cn?: string | null; condition?: boolean | null }
    | string;

type Maybe<U> = U | undefined | null;

const isNotNullable = <T extends unknown>(value: Maybe<T>): value is T =>
    value !== null && typeof value !== "undefined";

export const classNames = (
    ...classNamesVsConditions: Maybe<ClassNameCondition>[]
) => {
    return classNamesVsConditions
        .filter(isNotNullable)
        .filter((it) => (typeof it === "string" ? it : it.cn && it.condition))
        .map((it) => (typeof it === "string" ? it : it.cn))
        .join(" ");
};

export const iAmAnIframe = () =>
    window !== window.parent || window.top !== window.self;
