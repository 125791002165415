import "./Button.scss";
import React, { useRef } from "react";

type ButtonProps = {
    level?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
    id,
    level,
    type,
    className,
    onClick,
    children,
    ...props
}: ButtonProps) => {
    const cn = className ? ` ${className}` : "";
    const buttonRef = useRef<HTMLButtonElement>(null);
    const levelClassName = level ? ` ${level}` : " primary";
    return (
        <div
            id={id}
            className={"spond-gradient-button" + cn + levelClassName}
            tabIndex={0}
            onKeyDown={(e) => {
                e.key === "Enter" && buttonRef.current?.click();
            }}
        >
            <button
                ref={buttonRef}
                tabIndex={-1}
                type={type}
                className={"spb-button"}
                {...props}
                onClick={(e) => {
                    onClick && onClick(e);
                }}
            >
                <div className={"spb-button-content"}>{children}</div>
            </button>
        </div>
    );
};
export default Button;
