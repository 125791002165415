import React from "react";
import { useReferralCodeToFetchRecruiterInfo } from "./hooks";
import SignUpPage from "./sign-up/SignUpPage";
import FallbackWhenReferralCodeMissing from "./missing-referral-code/MissingReferralCodePage";
import ErrorOccurredPage from "./error-occured/ErrorOccurredPage";
import { useCountryCodeFromIpOrFallback } from "../../common/hooks";

const ReferralPageSelector = () => {
    const { recruiter, recruiterError, referralCode, isLoading } =
        useReferralCodeToFetchRecruiterInfo();

    if (isLoading) {
        return <div />;
    } else if (recruiter?.displayName && referralCode) {
        return <SignUpPage recruiter={recruiter} />;
    } else if (recruiterError === 404) {
        return <FallbackWhenReferralCodeMissing />;
    } else {
        return <ErrorOccurredPage />;
    }
};

export default ReferralPageSelector;
