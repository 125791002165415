const __SPOND_ENVIRONMENT__: Record<string, string> =
    (window as any).__SPOND_ENVIRONMENT__ || {};

export const API_URL =
    __SPOND_ENVIRONMENT__.API_URL || "http://localhost:18080";
export const STORAGE_API_URL = __SPOND_ENVIRONMENT__.STORAGE_API_URL || API_URL;
export const APP_VERSION = __SPOND_ENVIRONMENT__.APP_VERSION;

export const CORE_API = `${API_URL}/core/v1`;
export const ENVIRONMENT = __SPOND_ENVIRONMENT__.ENVIRONMENT || "local";
export const GA_TRACKING_ID = __SPOND_ENVIRONMENT__.GA_TRACKING_ID;
