import { MonetaryValue } from "../../../../../api/types";
import { TableField } from "../../../../../components/table/Table";
import { formatCurrency, hasValue } from "../../../../../common/utils/utils";
import { getI18n } from "react-i18next";

export const payoutRow = (
    payout: MonetaryValue,
    pendingTranslation: string
): TableField[] => {
    const dateOrPending: string =
        payout?.status === "PENDING"
            ? pendingTranslation || "Pending"
            : new Date(payout?.date).toLocaleString(
                  getI18n().resolvedLanguage || undefined,
                  {
                      dateStyle: "long",
                  }
              );
    const amount =
        hasValue(payout?.amount) && payout?.currency
            ? formatCurrency(
                  payout.amount / 100,
                  payout.currency,
                  getI18n().resolvedLanguage || null
              )
            : "";
    return [
        {
            className: "left",
            children: dateOrPending,
        },
        {
            className: "right",
            children: amount,
        },
    ];
};
