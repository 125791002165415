import Card from "../../../../../components/card/Card";
import React from "react";
import "./ReferralStatusCard.scss";
import "../card-styles.scss";

export interface ReferralStatusCardProps {
    title: string;
    icon: string;
    bigText: string;
    content: string;
}

const ReferralStatusCard = ({
    title,
    icon,
    bigText,
    content,
}: ReferralStatusCardProps) => {
    return (
        <Card
            tabIndex={0}
            className={"referral-status-card rsp-card-styling card-content"}
        >
            <div className={"title-icon"}>
                <b className={"title"}>{title}</b>
                <img className={"icon"} alt={"silhouette icon"} src={icon} />
            </div>
            <h1>{bigText}</h1>
            <p>{content}</p>
        </Card>
    );
};

export default ReferralStatusCard;
