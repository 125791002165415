import React from "react";
import "./Card.scss";

const Card = ({
    id,
    className,
    children,
    tabIndex,
}: {
    id?: string;
    className?: string;
    children: React.ReactNode;
    tabIndex?: number;
}) => {
    return (
        <div tabIndex={tabIndex} id={id} className={`card ${className || ""}`}>
            {children}
        </div>
    );
};

export default Card;
